let obl_05, obl_07, obl_09, obl_12, obl_14, obl_18, obl_21, obl_23, obl_26, obl_30, obl_32, obl_35, obl_40, obl_43, obl_46, obl_48, obl_51, obl_53, obl_56, obl_59, obl_61, obl_63, obl_65, obl_68, obl_71, obl_74, obl_77, office_1, office_2, office_3, office_4, locationpoint_1, locationpoint_2, locationpoint_3, locationpoint_4, locationpoint_5, locationpoint_6, locationpoint_7, locationpoint_8, locationpoint_9, locationpoint_10, locationpoint_11,
  location_1, location_2, location_3, location_4,  location_5, location_6,   location_7, location_8, location_9, location_10, location_11, location_12, location_13, location_14, location_15;

if (window.location.pathname.startsWith('/en/')) {
    // Англійська мова
    obl_05 = "Vinnytska";
    obl_07 = "Volynska";
    obl_09 = "Luhanska";
    obl_12 = "Dnipropetrovska";
    obl_14 = "Donetska";
    obl_18 = "Zhytomyrska";
    obl_21 = "Zakarpatska";
    obl_23 = "Zaporizka";
    obl_26 = "Ivano-Frankivska";
    obl_30 = "Kyivska";
    obl_32 = "Kyiv City";
    obl_35 = "Kirovohradska";
    obl_40 = "Sevastopol City";
    obl_43 = "AR Crimea";
    obl_46 = "Lvivska";
    obl_48 = "Mykolaivska";
    obl_51 = "Odeska";
    obl_53 = "Poltavska";
    obl_56 = "Rivnenska";
    obl_59 = "Sumska";
    obl_61 = "Ternopilska";
    obl_63 = "Kharkivska";
    obl_65 = "Khersonska";
    obl_68 = "Khmelnytska";
    obl_71 = "Cherkaska";
    obl_74 = "Chernihivska";
    obl_77 = "Chernivetska";
    office_1="Head office";
    office_2="Zaporishshia office";
    office_3="Kryvy Rih office";
    office_4="Kharkiv office";
    locationpoint_1="Water filters";
    locationpoint_2="Child space";
    locationpoint_3="Women space";
    locationpoint_4="Cash assistance";
    locationpoint_5="Non-cash assistance";
    locationpoint_6="Busines support";
    locationpoint_7="Winterisation (firewood)";
    locationpoint_8="Professional courses";
    locationpoint_9="Small repairs";
    locationpoint_10="Researches";
    locationpoint_11="Organization offices";
  location_1 = "Zakarpattia oblast";
  location_2="Zaporizhzhia city";
  location_3="Kryvy Rih city";
  location_4="Apostolove city";
  location_5="Zelenodolsk city";
  location_6="Kharkiv city";
  location_7 = "Merefa city";
  location_8 = "Uzhhorod city";
  location_9 = "Donetska oblast";
  location_10="Zaporizka oblast";
location_11="Dnipropetrovska oblast";
  location_12 = "Kharkivska oblast";
  location_13="Kyivska oblast";
location_14="Odeska oblast";
location_15="Khersonska oblast"
} else {
    // Українська мова
    obl_05 = "Вінницька";
    obl_07 = "Волинська";
    obl_09 = "Луганська";
    obl_12 = "Дніпропетровська";
    obl_14 = "Донецька";
    obl_18 = "Житомирська";
    obl_21 = "Закарпатська";
    obl_23 = "Запорізька";
    obl_26 = "Івано-Франківська";
    obl_30 = "Київська";
    obl_32 = "м. Київ";
    obl_35 = "Кіровоградська";
    obl_40 = "м. Севастополь";
    obl_43 = "АР Крим";
    obl_46 = "Львівська";
    obl_48 = "Миколаївська";
    obl_51 = "Одеська";
    obl_53 = "Полтавська";
    obl_56 = "Рівненська";
    obl_59 = "Сумська";
    obl_61 = "Тернопільська";
    obl_63 = "Харківська";
    obl_65 = "Херсонська";
    obl_68 = "Хмельницька";
    obl_71 = "Черкаська";
    obl_74 = "Чернігівська";
    obl_77 = "Чернівецька";
    office_1="Головний офіс організації";
    office_2="Запорізький осередок";
    office_3="Криворізький осередок";
    office_4="Харківський осередок";
    locationpoint_1="Фільтри для води";
    locationpoint_2="Дитячий простір";
    locationpoint_3="Жіночий простір";
    locationpoint_4="Грошова допомога";
    locationpoint_5="Гуманітарна допомога";
    locationpoint_6="Падтримка бізнесу";
    locationpoint_7="Підготовка до зими (видача дров)";
    locationpoint_8="Професійне навчання";
    locationpoint_9="Малі ремонти";
    locationpoint_10="Дослідження та опитування";
    locationpoint_11="Осередок організації";
  location_1 = "Закарпатська область";
  location_2="м. Запоріжжя";
  location_3="м. Кривий Ріг";
  location_4="м. Апостолове";
  location_5="м. Зеленодольськ";
  location_6="м. Харків";
  location_7 = "м. Мерефа";
  location_8 = "м. Ужгород";
  location_9="Донецька область";
location_10="Запорізька область";
location_11="Дніпропетровська область";
  location_12 = "Харківська область";
  location_13="Київська область";
  location_14="Одеська область";
  location_15="Херсонська область"
}

if (document.querySelector("[data-localpoint]")) {
  document.querySelector('[data-localpoint="1"]').innerHTML = ` - ${locationpoint_1}`;
  document.querySelector('[data-localpoint="2"]').innerHTML = ` - ${locationpoint_2}`;
  document.querySelector('[data-localpoint="3"]').innerHTML = ` - ${locationpoint_3}`;
  document.querySelector('[data-localpoint="4"]').innerHTML = ` - ${locationpoint_4}`;
  document.querySelector('[data-localpoint="5"]').innerHTML = ` - ${locationpoint_5}`;
  document.querySelector('[data-localpoint="6"]').innerHTML = ` - ${locationpoint_6}`;
  document.querySelector('[data-localpoint="7"]').innerHTML = ` - ${locationpoint_7}`;
  document.querySelector('[data-localpoint="8"]').innerHTML = ` - ${locationpoint_8}`;
  document.querySelector('[data-localpoint="9"]').innerHTML = ` - ${locationpoint_9}`;
  document.querySelector('[data-localpoint="10"]').innerHTML = ` - ${locationpoint_10}`;
  document.querySelector('[data-localpoint="11"]').innerHTML = ` - ${locationpoint_11}`;
}

var simplemaps_countrymap_mapdata =
{
  main_settings: {
   //General settings
    width: "responsive", //'700' or 'responsive'
    background_color: "#FFFFFF",
    background_transparent: "yes",
    border_color: "#F5898D",
    state_description: "",
    state_color: "#b2b2b2",
    state_opacity:.7,
    state_hover_color: "#f5898d",
    state_url: "",
    border_size: "1",
    all_states_inactive: "yes",
    all_states_zoomable: "no",
    location_description: "",
    location_url: "",
    location_color: "#FF0067",
    location_opacity: ".5",
    location_hover_opacity: 1,
    location_size: "20",
    location_type: "image",
    location_image_source: "frog.png",
    location_border_color: "#FFFFFF",
    location_border: 2,
    location_hover_border: 2.5,
    all_locations_inactive: "no",
    all_locations_hidden: "no",
    
    //Label defaults
    label_color: "#ED1C24",
    label_hover_color: "#ffffff",
    label_size: "8",
    label_font: "inherit",
    label_display: "all",
    label_scale: "yes",
    hide_labels: "no",
    hide_eastern_labels: "no",
   
    //Zoom settings
    zoom: "no",
    manual_zoom: "no",
    back_image: "no",
    initial_back: "no",
    initial_zoom: "-1",
    initial_zoom_solo: "no",
    region_opacity: 1,
    region_hover_opacity: 0.6,
    zoom_out_incrementally: "yes",
    zoom_percentage: 0.99,
    zoom_time: 0.5,
    
    //Popup settings
    popup_color: "white",
    popup_opacity: 0.9,
    popup_shadow: 1,
    popup_corners: 5,
    popup_font: "12px/1.5 inherit,Verdana, Arial, Helvetica, sans-serif",
    popup_nocss: "no",
    
    //Advanced settings
    div: "map",
    auto_load: "yes",
    url_new_tab: "no",
    images_directory: "default",
    fade_time: 0.1,
    link_text: "View Website",
    popups: "detect",
    state_image_url: "",
    state_image_position: "",
    location_image_url: "./img/map_icons/logotype.webp",
    border_hover_color: "#ED1C24"
  },
  state_specific: {
    UA05: {
      name: obl_05
    },
    UA07: {
      name: obl_07
    },
    UA09: {
      name: obl_09
    },
    UA12: {
      name: obl_12,
      color: "#ffffff",
      opacity:1
    },
    UA14: {
      name: obl_14,
      color: "#d9d9d9",
      opacity:.9
    },
    UA18: {
      name: obl_18
    },
    UA21: {
      name: obl_21,
      color: "#ffffff",
      opacity:1
    },
    UA23: {
      name: obl_23,
      color: "#ffffff",
      opacity:1
    },
    UA26: {
      name: obl_26
    },
    UA30: {
      name: obl_30,
      color: "#d9d9d9",
      opacity:.9
    },
    UA32: {
      name: obl_32
    },
    UA35: {
      name: obl_35
    },
    UA40: {
      name: obl_40
    },
    UA43: {
      name: obl_43
    },
    UA46: {
      name: obl_46
    },
    UA48: {
      name: obl_48
    },
    UA51: {
      name: obl_51,
      color: "#d9d9d9",
      opacity:.9
    },
    UA53: {
      name: obl_53
    },
    UA56: {
      name: obl_56
    },
    UA59: {
      name: obl_59
    },
    UA61: {
      name: obl_61
    },
    UA63: {
      name: obl_63,
      color: "#ffffff",
      opacity:1
    },
    UA65: {
      name: obl_65,
      color: "#d9d9d9",
      opacity:.9
    },
    UA68: {
      name: obl_68
    },
    UA71: {
      name: obl_71
    },
    UA74: {
      name: obl_74
    },
    UA77: {
      name: obl_77
    }
  },
  locations: {
    // NOTE: offices
    "of_0": {
      lat: 48.626,
      lng: 22.285,
      name: office_1,
      size: "25"
    },
    "of_1": {
      lat: 47.829,
      lng: 35.163,
      name: office_2
    },
    "of_2": {
      lat: 47.908,
      lng: 33.386,
      name: office_3
    },
    "of_3": {
      lat: 49.994,
      lng: 36.203,
      name: office_4
    },
    // NOTE: water filters
    "filter_0": {
      lat: 48.491,
      lng: 22.727,
      name: locationpoint_1,
      description: location_1,
      image_url:"./img/map_icons/potable_water.webp"
      
    },
    "filter_1": {
      lat: 47.829,
      lng: 35.363,
      name: locationpoint_1,
      description: location_2,
      image_url:"./img/map_icons/potable_water.webp"
    },
    "filter_2": {
      lat:47.908,
      lng:33.586,
      name: locationpoint_1,
      description: location_3,
      image_url:"./img/map_icons/potable_water.webp"
    },
    "filter_3": {
      lat: 47.663,
      lng: 33.719,
      name: locationpoint_1,
      description: location_4,
      image_url: "./img/map_icons/potable_water.webp"
    },
    "filter_4": {
      lat: 47.570,
      lng: 33.650,
      name: locationpoint_1,
      description: location_5,
      image_url: "./img/map_icons/potable_water.png"
      
    },
    "filter_5": {
      lat:49.979,
      lng:36.441,
      name: locationpoint_1,
      description: location_6,
      image_url:"./img/map_icons/potable_water.webp"
    },
    "filter_6": {
      lat: 49.810,
      lng: 36.056,
      name: locationpoint_1,
      description: location_7,
      image_url:"./img/map_icons/potable_water.webp"
    },
    // NOTE: child spaces
    "child_space_0":{
      lat: 48.914,
      lng: 22.275,
      name: locationpoint_2,
      description: location_8,
      image_url: "./img/map_icons/woman-girl-boy.webp"
    },
    "child_space_1":{
      lat: 48.129,
      lng: 35.163,
      name: locationpoint_2,
      description: location_2,
      image_url: "./img/map_icons/woman-girl-boy.webp"
    },
    "child_space_2":{
      lat: 48.208,
      lng: 33.386,
      name: locationpoint_2,
      description: location_3,
      image_url: "./img/map_icons/woman-girl-boy.webp"
    },
    // NOTE: women spaces
    "women_space_0":{
      lat: 48.914,
      lng: 22.575,
      name: locationpoint_3,
      description: location_8,
      image_url: "./img/map_icons/ok_woman.webp"
    },
    "women_space_1":{
      lat: 48.129,
      lng: 35.483,
      name: locationpoint_3,
      description: location_2,
      image_url: "./img/map_icons/ok_woman.webp"
    },
    "women_space_2":{
      lat: 48.208,
      lng: 33.686,
      name: locationpoint_3,
      description: location_3,
      image_url: "./img/map_icons/ok_woman.webp"
    },
    // NOTE: cache assistance
    "cache_0":{
      lat: 48.491,
      lng: 23.127,
      name: locationpoint_4,
      description: location_1,
      image_url: "./img/map_icons/money_with_wings.webp"
    },
    "cache_1":{
      lat: 47.529,
      lng: 35.463,
      name: locationpoint_4,
      description: location_2,
      image_url: "./img/map_icons/money_with_wings.webp"
    },
    "cache_2":{
      lat: 48.008,
      lng: 34.086,
      name: locationpoint_4,
      description: location_3,
      image_url: "./img/map_icons/money_with_wings.webp"
    },
    "cache_3":{
      lat: 49.508,
      lng: 36.086,
      name: locationpoint_4,
      description: location_7,
      image_url: "./img/map_icons/money_with_wings.webp"
    },
    "cache_4":{
      lat: 48.045,
      lng: 37.466,
      name: locationpoint_4,
      description: location_9,
      image_url: "./img/map_icons/money_with_wings.webp"
    },
    // NOTE: humanitarian assistance
    "humanitarian_0":{
      lat: 48.291,
      lng: 23.427,
      name: locationpoint_5,
      description: location_1,
      image_url: "./img/map_icons/package.webp"
    },
    "humanitarian_1":{
      lat: 47.529,
      lng: 35.763,
      name: locationpoint_5,
      description: location_2,
      image_url: "./img/map_icons/package.webp"
    },
    "humanitarian_2":{
      lat: 47.708,
      lng: 34.286,
      name: locationpoint_5,
      description: location_3,
      image_url: "./img/map_icons/package.webp"
    },
    "humanitarian_3":{
      lat: 49.632,
      lng: 37.370,
      name: locationpoint_5,
      description: location_12,
      image_url: "./img/map_icons/package.webp"
    },
    "humanitarian_4":{
      lat: 46.650,
      lng: 33.436,
      name: locationpoint_5,
      description: location_15,
      image_url: "./img/map_icons/package.webp"
    },
    "humanitarian_5":{
      lat: 48.045,
      lng: 37.766,
      name: locationpoint_5,
      description: location_9,
      image_url: "./img/map_icons/package.webp"
    },
    // NOTE: business support
    "business_support_0":{
      lat: 48.291,
      lng: 23.757,
      name: locationpoint_6,
      description: location_1,
      image_url: "./img/map_icons/chart_with_upwards_trend.webp"
    },
    "business_support_1":{
      lat: 47.529,
      lng: 35.423,
      name: locationpoint_6,
      description: location_10,
      image_url: "./img/map_icons/chart_with_upwards_trend.webp"
    },
    "business_support_2":{
      lat: 48.508,
      lng: 34.286,
      name: locationpoint_6,
      description: location_11,
      image_url: "./img/map_icons/chart_with_upwards_trend.webp"
    },
    "business_support_3":{
      lat: 49.632,
      lng: 37.070,
      name: locationpoint_6,
      description: location_12,
      image_url: "./img/map_icons/chart_with_upwards_trend.webp"
    },
    // NOTE: winterization
    "winterization_0":{
      lat: 48.091,
      lng: 24.157,
      name: locationpoint_7,
      description: location_1,
      image_url: "./img/map_icons/snowflake.webp"
    },
    "winterization_1":{
      lat: 47.529,
      lng: 36.023,
      name: locationpoint_7,
      description: location_10,
      image_url: "./img/map_icons/snowflake.webp"
    },
    "winterization_2":{
      lat: 48.508,
      lng: 34.586,
      name: locationpoint_7,
      description: location_11,
      image_url: "./img/map_icons/snowflake.webp"
    },
    "winterization_3":{
      lat: 49.332,
      lng: 37.220,
      name: locationpoint_7,
      description: location_12,
      image_url: "./img/map_icons/snowflake.webp"
    },
        // NOTE: carrier_courses
    "carrier_courses_0":{
      lat: 48.591,
      lng: 23.527,
      name: locationpoint_8,
      description: location_1,
      image_url: "./img/map_icons/female-student.webp"
    },
    "carrier_courses_1":{
      lat: 47.829,
      lng: 35.763,
      name: locationpoint_8,
      description: location_2,
      image_url: "./img/map_icons/female-student.webp"
    },
    "carrier_courses_2":{
      lat: 48.208,
      lng: 34.486,
      name: locationpoint_8,
      description: location_3,
      image_url: "./img/map_icons/female-student.webp"
    },
    "carrier_courses_3":{
      lat: 49.932,
      lng: 37.170,
      name: locationpoint_8,
      description: location_12,
      image_url: "./img/map_icons/female-student.webp"
    },
    "carrier_courses_4":{
      lat: 50.012,
      lng: 30.251,
      name: locationpoint_8,
      description: location_13,
      image_url: "./img/map_icons/female-student.webp"
    },
        // NOTE: small_repairs
    "small_repairs_0":{
      lat: 48.591,
      lng: 23.927,
      name: locationpoint_9,
      description: location_1,
      image_url: "./img/map_icons/hammer_and_wrench.webp"
    },
    "small_repairs_1":{
      lat: 47.529,
      lng: 35.023,
      name: locationpoint_9,
      description: location_2,
      image_url: "./img/map_icons/hammer_and_wrench.webp"
    },
    "small_repairs_2":{
      lat: 48.508,
      lng: 34.886,
      name: locationpoint_9,
      description: location_3,
      image_url: "./img/map_icons/hammer_and_wrench.webp"
    },
    "small_repairs_3":{
      lat: 49.132,
      lng: 36.270,
      name: locationpoint_9,
      description: location_12,
      image_url: "./img/map_icons/hammer_and_wrench.webp"
    },

        // NOTE: researches
    "researches_0":{
      lat: 48.791,
      lng: 23.227,
      name: locationpoint_10,
      description: location_1,
      image_url: "./img/map_icons/bar_chart.webp"
    },
    "researches_1":{
      lat: 47.029,
      lng: 35.763,
      name: locationpoint_10,
      description: location_2,
      image_url: "./img/map_icons/bar_chart.webp"
    },
    "researches_2":{
      lat: 48.808,
      lng: 34.486,
      name: locationpoint_10,
      description: location_3,
      image_url: "./img/map_icons/bar_chart.webp"
    },
    "researches_3":{
      lat: 49.032,
      lng: 36.770,
      name: locationpoint_10,
      description: location_12,
      image_url: "./img/map_icons/bar_chart.webp"
    },
    "researches_4":{
      lat: 46.894,
      lng: 30.179,
      name: locationpoint_10,
      description: location_14,
      image_url: "./img/map_icons/bar_chart.webp"
    },
  },
labels: {
    "0": {
      name: obl_05,
      parent_id: "UA05",
      x: 376.9,
      y: 310.6
    },
    "1": {
      name: obl_07,
      parent_id: "UA07",
      x: 178.2,
      y: 121.6
    },
    "2": {
      name: obl_09,
      parent_id: "UA09",
      x: 891.4,
      y: 279.3
    },
    "3": {
      name: obl_12,
      parent_id: "UA12",
      x: 694.8,
      y: 328.7
    },
    "4": {
      name: obl_14,
      parent_id: "UA14",
      x: 831.3,
      y: 375.8
    },
    "5": {
      name: obl_18,
      parent_id: "UA18",
      x: 359.9,
      y: 162
    },
    "6": {
      name: obl_21,
      parent_id: "UA21",
      x: 84.3,
      y: 339.6
    },
    "7": {
      name: obl_23,
      parent_id: "UA23",
      x: 733.8,
      y: 429.4
    },
    "8": {
      name: obl_26,
      parent_id: "UA26",
      x: 170.9,
      y: 321.5
    },
    "9": {
      name: obl_30,
      parent_id: "UA30",
      x: 469.5,
      y: 202.9
    },
    "10": {
      name: obl_32,
      parent_id: "UA32",
      x: 472.2,
      y: 186
    },
    "11": {
      name: obl_35,
      parent_id: "UA35",
      x: 561.7,
      y: 341.7
    },
    "12": {
      name: obl_40,
      parent_id: "UA40",
      x: 619,
      y: 623.9
    },
    "13": {
      name: obl_43,
      parent_id: "UA43",
      x: 655.5,
      y: 573.7
    },
    "14": {
      name: obl_46,
      parent_id: "UA46",
      x: 123,
      y: 250.5
    },
    "15": {
      name: obl_48,
      parent_id: "UA48",
      x: 540.4,
      y: 428.1
    },
    "16": {
      name: obl_51,
      parent_id: "UA51",
      x: 453.7,
      y: 432.3
    },
    "17": {
      name: obl_53,
      parent_id: "UA53",
      x: 630.3,
      y: 241.5
    },
    "18": {
      name: obl_56,
      parent_id: "UA56",
      x: 276.2,
      y: 115.7
    },
    "19": {
      name: obl_59,
      parent_id: "UA59",
      x: 681,
      y: 160.6
    },
    "20": {
      name: obl_61,
      parent_id: "UA61",
      x: 217.8,
      y: 272.2
    },
    "21": {
      name: obl_63,
      parent_id: "UA63",
      x: 774.1,
      y: 256.8
    },
    "22": {
      name: obl_65,
      parent_id: "UA65",
      x: 635.3,
      y: 463.7
    },
    "23": {
      name: obl_68,
      parent_id: "UA68",
      x: 289.5,
      y: 261.1
    },
    "24": {
      name: obl_71,
      parent_id: "UA71",
      x: 532.4,
      y: 267.1
    },
    "25": {
      name: obl_74,
      parent_id: "UA74",
      x: 534.3,
      y: 114.8
    },
    "26": {
      name: obl_77,
      parent_id: "UA77",
      x: 237.6,
      y: 356.8
    }
  },
  legend: {
    entries: []
  },
  regions: {}
};